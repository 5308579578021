import React from "react";
import { connect } from "react-redux";
import {
  initializeResourcesPage,
  changeResourcesContainerPage,
} from "../../redux/actions/resources";
import { navigateToPath } from "../../redux/actions/ui";
import getQueryStringParams from "../../util/getQueryStringParams";
import PageLoader from "../loader/PageLoader";
import Pagination from "../shared/pagination/Pagination";
import ContainerTile from "../shared/containerTile/ContainerTile";
import PageHeader from "../shared/PageHeader";

class ResourcesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.changePage = this.changePage.bind(this);
    this.handleTileClick = this.handleTileClick.bind(this);
  }

  componentDidMount() {
    const pageNumber =
      getQueryStringParams(this.props.location?.search).page || 1;
    this.props.dispatch(initializeResourcesPage(pageNumber));
  }

  componentDidUpdate(prevProps) {
    if (this.props.location?.search !== prevProps.location?.search) {
      const pageNumber =
        getQueryStringParams(this.props.location?.search).page || 1;
      this.props.dispatch(initializeResourcesPage(pageNumber));
    }
  }

  handleTileClick(id) {
    this.props.dispatch(navigateToPath(`/resources/${id}`));
  }

  changePage(page) {
    this.props.dispatch(changeResourcesContainerPage(page));
  }

  render() {
    const { loading, error, errorMessage, items, pagination } = this.props;

    let { page, totalPages } = pagination;

    page = page || 1;

    return (
      <PageLoader
        loading={loading}
        error={error}
        errorMessage={errorMessage}
        fadeOut={true}
      >
        <section
          data-cy="sect-resources-container"
          className="resources-container"
        >
          <PageHeader headerId="resources" subheaderId="resourcesSubheader" />
          <div className="resources-list tile-list row">
            {items.map((item) => {
              return (
                <ContainerTile
                  {...item}
                  key={item.ID}
                  type="resource"
                  container="resource"
                  handleTileClick={this.handleTileClick}
                  textLength={200}
                />
              );
            })}
          </div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handleClick={this.changePage}
          />
        </section>
      </PageLoader>
    );
  }
}

const mapStateToProps = (store) => {
  return { ...store.resources };
};

export default connect(mapStateToProps)(ResourcesContainer);
