import React, { useEffect } from "react";
import Tooltip from "../shared/tooltip/Tooltip";
import { useIntl, defineMessages } from "react-intl";

/**
 * Page Header component displays a header and subheader text
 * accepts either react-intl ids as "headerId, subheaderId" or
 * strings as "headerText, subheaderText"
 *
 * If you would like the beta flag to appear, pass betaFlag = true
 * IF you would like to have a tooltip when hover on the beta flag, pass the text in
 */

const PageHeader = ({
  headerId,
  subheaderId,
  headerText,
  subheaderText,
  betaFlag = false,
  betaFlagTooltipText = "",
}) => {
  const intl = useIntl();
  const messageIds = [headerId, subheaderId].filter((id) => id?.length > 0);
  const messages = defineMessages(
    messageIds.reduce((obj, key) => {
      obj[key] = { id: key };
      return obj;
    }, {}),
  );

  const header =
    headerText || headerId
      ? headerText
        ? headerText
        : intl.formatMessage(messages[headerId])
      : "";

  const subheader =
    subheaderText || subheaderId
      ? subheaderText
        ? subheaderText
        : intl.formatMessage(messages[subheaderId])
      : "";

  useEffect(() => {
    if (!header.length) {
      console.warn("No headerId or headerText prop provided.");
    }
  }, [header]);

  return (
    <header data-cy={`header-${headerId}`} className="page-header">
      <h2>
        {header}
        {betaFlag ? (
          <Tooltip
            className="nav-tooltip"
            content={betaFlagTooltipText}
            placement="right"
            disable={!betaFlagTooltipText.length}
          >
            <span className="beta-flag">beta</span>
          </Tooltip>
        ) : null}
        {subheader && <small>{subheader}</small>}
      </h2>
    </header>
  );
};

export default PageHeader;
