import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { FormattedMessage } from "react-intl";

const DashboardHeader = () => {
  const { firstName } = useSelector((state) => {
    return {
      firstName: state.user.firstName || "",
    };
  }, shallowEqual);
  const date = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const today = date.toLocaleString("en", options);
  // localized date string!
  // en: 'February 21, 2020', de: '21. Februar 2020' , fr: '21 février 2020'
  // re-support this if localization ever comes back

  return (
    <header>
      <h2>
        <span data-cy="span-dashboard-header-greeting" className="greeting">
          <FormattedMessage
            id={firstName?.length ? "greeting" : "greetingNoFirstName"}
            values={{ firstName }}
            defaultMessage={"Hello!"}
          />
        </span>
        <span className="date">
          <FormattedMessage
            id="today"
            values={{ today }}
            defaultMessage={`Today is ${today}`}
          />
        </span>
      </h2>
    </header>
  );
};

export default DashboardHeader;
