import React from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { gaSaveDocumentEvent } from "../../redux/actions/ga";

const ListContent = ({ items }) => {
  const noAccountTitle = (
    <FormattedMessage
      id="unknownAccountName"
      defaultMessage="Unknown Account Name"
    />
  );

  const dispatch = useDispatch();

  const ga = ({ item }) => {
    dispatch(
      gaSaveDocumentEvent({
        document_id: item.document_id,
        reference_date: item.reference_date,
        document_type: item.document_type,
      }),
    );
  };

  const documentTypes = {
    financial_statement: "Statement",
    creator_yt_asset_revenue: "SRAV Asset Revenue",
    creator_yt_video_revenue: "SRAV Video Revenue",
  };

  items = items || [];

  return items.map((item) => {
    //adding 0s in front of id with single digit months like '2020-5'
    const yearMonthDay = item.document_id
      .split("-")
      .map((n) => (n < 10 ? (n = `0${n}`) : n));
    //document_id usually comes in as YYYY-MM but also YYYY-MM-DD. adding DD to YYYY-MM here
    //iso set to second day of the month to ensure no time zone weirdness

    if (yearMonthDay.length === 2) {
      yearMonthDay.push("02");
    }
    const date = moment(`${yearMonthDay.join("-")}T00:00:00.000Z`).format(
      "MMMM YYYY",
    );
    return (
      <li
        key={`${item.entity_id}-${item.document_id}`}
        className="list-content"
      >
        <div className="item-data">
          <div className="date">{date}</div>
          <div className="account">{item.account_title || noAccountTitle}</div>
          <div className="type">
            {documentTypes[item.document_type] || "Document"}
          </div>
        </div>
        <div className="save">
          <a
            onClick={() => ga({ item })}
            href={item.data}
            target="_blank"
            rel="noopener noreferrer"
            data-cy="a-document-download"
          >
            <span className="icon">
              <i className="fas fa-download" />
            </span>
            <span>
              <FormattedMessage id="save" defaultMessage="Save" />
            </span>
          </a>
        </div>
      </li>
    );
  });
};

export default ListContent;
