import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { cancelAccountSettingsChange } from "../../../redux/actions/settings";
import PageLoader from "../../loader/PageLoader";
import ErrorMessage from "./ErrorMessage";

const ActionButtons = ({
  enableSaveButton,
  loading,
  showSuccessMessage,
  errorMessage,
  dispatch,
}) => {
  const handleCancelClick = (e) => {
    e.preventDefault();
    dispatch(cancelAccountSettingsChange());
  };

  return (
    <div className="actions">
      {showSuccessMessage && (
        <em data-cy="em-changes-successful" className="success-message">
          <FormattedMessage
            id="changesSaved"
            defaultMessage="Changes successfully saved!"
          />
        </em>
      )}
      <ErrorMessage errorMessage={errorMessage} />
      <button onClick={handleCancelClick}>
        <FormattedMessage id="cancel" defaultMessage="Cancel" />
      </button>
      <button
        data-cy="btn-account-settings-save"
        type="submit"
        className={`secondary${loading ? " loading" : ""}${
          !enableSaveButton ? " disabled" : ""
        }`}
      >
        <PageLoader loading={loading} size="x-small">
          <FormattedMessage id="save" devaultMessage="Save" />
        </PageLoader>
      </button>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  return {
    enableSaveButton: settings.account.enableSaveButton,
    loading: settings.account.loading,
    showSuccessMessage: settings.account.showSuccessMessage,
    errorMessage: settings.account.errorMessage,
  };
};

export default connect(mapStateToProps)(ActionButtons);
