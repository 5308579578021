import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import Modal from "../modal/Modal";
import { ignoreAuthRoadblock, getChannels } from "../../redux/actions/settings";
import { navigateToPath } from "../../redux/actions/ui";

const AuthRoadblockModal = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const userId = useSelector((state) => state.user.id);
  const feeds = useSelector((state) => state.user.feeds || [], shallowEqual);
  const channels = useSelector(
    (state) => state.settings.channel.channels || [],
    shallowEqual,
  );

  const [opened, setOpened] = useState(false);

  const hasUnauthedChannel = channels.some(
    ({ progress }) => progress && !progress?.authorized,
  );

  const handleClose = () => setOpened(false);

  const handleCancelClick = () => {
    dispatch(ignoreAuthRoadblock());
    handleClose();
  };

  const handleAuthNowClick = () => {
    handleClose();
    dispatch(navigateToPath("/settings/channels"));
  };

  useEffect(() => {
    dispatch(getChannels());
  }, [dispatch]);

  useEffect(() => {
    if (loggedIn && userId && feeds.length && hasUnauthedChannel) {
      const ignoreExpirationDate = localStorage.getItem(
        `ignoreAuthRoadblock${userId}`,
      );
      (!ignoreExpirationDate || moment().isAfter(ignoreExpirationDate)) &&
        setOpened(true);
    }
  }, [loggedIn, userId, feeds, hasUnauthedChannel]);

  return (
    <Modal
      className="auth-roadblock"
      opened={opened}
      hideCloseButton
      closeModal={handleClose}
      header={<FormattedMessage id="authRoadblockHeader" />}
    >
      <>
        <p>
          <FormattedMessage id="authRoadblockContent" />
        </p>
        <div className="action-buttons">
          <button className="primary" onClick={handleAuthNowClick}>
            <FormattedMessage id="authenticateNow" />
          </button>
          <button
            data-cy="btn-finish-later"
            className="link"
            onClick={handleCancelClick}
          >
            <FormattedMessage id="finishLater" />
          </button>
        </div>
      </>
    </Modal>
  );
};

export default AuthRoadblockModal;
