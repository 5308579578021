import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Redirect } from "react-router";
import { getLoginToken } from "../../redux/actions/user";
import LoginError from "./LoginError";
import UserGroupAuthError from "./UserGroupAuthError";
import CreatorStudioLogoHeader from "../shared/CreatorStudioLogoHeader";
import PageLoader from "../loader/PageLoader";
import { FormattedMessage } from "react-intl";

/**
 * Login Flow functions
 *  after you click the login button, backend redirects you back to
 *  here with a code in the URL
 */

const LoginContainer = (props) => {
  const dispatch = useDispatch();
  const loginError = useSelector((state) => state.user.loginError);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const logoutPending = useSelector((state) => state.user.logoutPending);
  const destinationPage = useSelector((state) => state.ui.destinationPage);
  const userGroupAuthError = useSelector(
    (state) => state.ui.userGroupAuthError,
  );
  const authUrlFetching = useSelector((state) => state.ui.authUrlFetching);
  const matchedRestrictionGroup = useSelector(
    (state) => state.user.matchedRestrictionGroup || {},
    shallowEqual,
  );
  const redirectTo =
    matchedRestrictionGroup?.restrictedRoutes?.indexOf(destinationPage) >= 0
      ? "/"
      : destinationPage;
  const referer = props.location?.state?.from?.pathname || "/";

  const login = () => {
    dispatch(getLoginToken(referer));
  };

  if (loggedIn && !logoutPending) {
    return <Redirect to={redirectTo} />;
  }

  if (userGroupAuthError) {
    return <UserGroupAuthError />;
  }

  return (
    <div className="login-page">
      <div className="overlay" />
      <CreatorStudioLogoHeader />
      <div className="login-container">
        <div className="logo">
          <h1>Studio 71</h1>
        </div>
        <button
          onClick={login}
          className={`primary${authUrlFetching ? " disabled" : ""}`}
          data-cy="btn-login-button"
        >
          <PageLoader
            size="small"
            transparent
            loading={authUrlFetching}
            render={() => <FormattedMessage id="signIn" />}
          />
        </button>
        {loginError && <LoginError />}
      </div>
    </div>
  );
};

export default LoginContainer;
