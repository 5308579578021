import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { gaFooterItemClick } from "../redux/actions/ga";

const linkUrls = {
  en: {
    s71: "https://www.studio71.com/us/us-privacy/",
    google: "https://policies.google.com/privacy",
    youtube: "https://www.youtube.com/t/terms",
  },
};

const Footer = ({ locale }) => {
  const dispatch = useDispatch();
  const urls = linkUrls[locale] || linkUrls["en"];

  const s71PrivacyGa = () => {
    dispatch(gaFooterItemClick({ item: "studio71 privacy policy" }));
  };

  const googlePrivacyGa = () => {
    dispatch(gaFooterItemClick({ item: "google privacy policy" }));
  };

  const ytTermsGa = () => {
    dispatch(gaFooterItemClick({ item: "youtube terms of service" }));
  };

  return (
    <div className="app-footer container-fluid">
      <ul className="privacy-links">
        <li onClick={s71PrivacyGa}>
          <a href={urls.s71} target="_blank" rel="noopener noreferrer">
            <FormattedMessage
              id="privacyPolicy"
              values={{ company: "Studio71" }}
            />
          </a>
        </li>
        <li onClick={googlePrivacyGa}>
          <a href={urls.google} target="_blank" rel="noopener noreferrer">
            <FormattedMessage
              id="privacyPolicy"
              values={{ company: "Google" }}
            />
          </a>
        </li>
        <li onClick={ytTermsGa}>
          <a href={urls.youtube} target="_blank" rel="noopener noreferrer">
            <FormattedMessage
              id="termsOfService"
              values={{ company: "YouTube" }}
            />
          </a>
        </li>
      </ul>
      <ul className="company-info">
        <li>
          &copy;{" "}
          <FormattedMessage
            id="allRightsReserved"
            values={{ company: "Studio71" }}
          />
        </li>
      </ul>
    </div>
  );
};

export default Footer;
