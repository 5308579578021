import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl, defineMessages } from "react-intl";
import { getPaymentSettings } from "../../../redux/actions/settings";
import CustomDropdown from "../../shared/customDropdown/CustomDropdown";
import PageLoader from "../../loader/PageLoader";
import PageHeader from "../../shared/PageHeader";
import PaymentInfoTable from "./PaymentInfoTable";
import BREAKPOINTS from "../../../constants/breakpoints";

const PaymentSettingsContainer = () => {
  const dispatch = useDispatch();
  const paymentSettingsAccounts = useSelector(
    (state) => state.user.paymentSettingsAccounts ?? [],
  );
  const loading = useSelector((state) => state.settings.payment.loading);
  const error = useSelector((state) => state.settings.payment.error);
  const errorMessageKey = useSelector(
    (state) => state.settings.payment.errorMessageKey,
  );
  const paymentInfo = useSelector(
    (state) => state.settings.payment.paymentInfo,
  );

  const [selectedAccount, setSelectedAccount] = useState("all");

  const intl = useIntl();
  const messages = defineMessages({
    allAccounts: { id: "allAccounts" },
    filterLabel: { id: "filterByAccount" },
    noTaxForm: { id: "noTaxForm" },
    noTaxFormShort: { id: "noTaxFormShort" },
    noBank: { id: "noBank" },
    noBankShort: { id: "noBankShort" },
    account: { id: "account" },
    activeTaxFormType: { id: "activeTaxFormType" },
    expirationDate: { id: "expirationDate" },
    accountHolder: { id: "accountHolder" },
    paymentMethod: { id: "paymentMethod" },
    genericPaymentLoadingError: { id: "genericPaymentLoadingError" },
    noAccessPaymentLoadingError: { id: "noAccessPaymentLoadingError" },
    taxDetails: { id: "taxDetails" },
    bankDetails: { id: "bankDetails" },
    bankName: { id: "bankName" },
    paymentSettingsBetaTooltip: { id: "paymentSettingsBetaTooltip" },
  });

  const isTablet = window.innerWidth < BREAKPOINTS.TABLET;

  // init page with call to backend for data
  useEffect(() => {
    dispatch(
      getPaymentSettings({
        accountIds:
          selectedAccount === "all"
            ? paymentSettingsAccounts.map(({ id }) => id).join(",")
            : selectedAccount,
      }),
    );
  }, [paymentSettingsAccounts, dispatch, selectedAccount]);

  const accountFilterOptions = [
    { label: intl.formatMessage(messages.allAccounts), value: "all" },
  ].concat(
    paymentSettingsAccounts
      .map(({ id, title }) => {
        return {
          label: title,
          value: id,
        };
      })
      ?.sort(({ label: a }, { label: b }) => (a || "").localeCompare(b || "")),
  );

  const handleDropdownChange = (id) => {
    setSelectedAccount(id);
  };

  const taxFields = [
    { display: intl.formatMessage(messages.account), fieldName: "account_id" },
    {
      display: intl.formatMessage(messages.activeTaxFormType),
      fieldName: "tax_document_type",
    },
    {
      display: intl.formatMessage(messages.expirationDate),
      fieldName: "tax_expiry",
    },
  ];

  const bankingFields = [
    { display: intl.formatMessage(messages.account), fieldName: "account_id" },
    {
      display: intl.formatMessage(messages.accountHolder),
      fieldName: "account_holder",
    },
    {
      display: intl.formatMessage(messages.bankName),
      fieldName: "bank_name",
    },
    {
      display: intl.formatMessage(messages.paymentMethod),
      fieldName: "payment_method",
    },
  ];

  return (
    <PageLoader
      loading={loading}
      error={error}
      errorMessage={intl.formatMessage(messages[errorMessageKey])}
      size="medium"
    >
      <section
        data-cy="sect-payment-settings-container"
        className="payment-settings-container col-12"
      >
        <PageHeader
          headerId="paymentSettings"
          subheaderId="paymentSettingsDescription"
          betaFlag={true}
          betaFlagTooltipText={intl.formatMessage(
            messages.paymentSettingsBetaTooltip,
          )}
        />
        <CustomDropdown
          labelText={intl.formatMessage(messages.filterLabel)}
          items={accountFilterOptions}
          className="opaque-container payment-dropdown"
          selected={selectedAccount}
          onChange={handleDropdownChange}
        />
        <div className="details-container order-2 order-md-2">
          <h3>{intl.formatMessage(messages.taxDetails)}</h3>
          <div className="row container-preview-area opaque-container">
            <PaymentInfoTable
              fields={taxFields}
              accounts={paymentSettingsAccounts}
              paymentInfo={paymentInfo}
              noDataField="tax_document_type"
              bankOrTax="tax"
              noDataMessage={
                isTablet
                  ? intl.formatMessage(messages.noTaxFormShort)
                  : intl.formatMessage(messages.noTaxForm)
              }
            />
          </div>
        </div>
        <div className="details-container">
          <h3>{intl.formatMessage(messages.bankDetails)}</h3>
          <div className="row container-preview-area opaque-container">
            <PaymentInfoTable
              fields={bankingFields}
              accounts={paymentSettingsAccounts}
              paymentInfo={paymentInfo}
              noDataField="account_holder"
              bankOrTax="bank"
              noDataMessage={
                isTablet
                  ? intl.formatMessage(messages.noBankShort)
                  : intl.formatMessage(messages.noBank)
              }
            />
          </div>
        </div>
      </section>
    </PageLoader>
  );
};

export default PaymentSettingsContainer;
