import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { initializeDashboard } from "../../redux/actions/dashboard";
import { navigateToPath } from "../../redux/actions/ui";
import { loadBlogDetailPage } from "../../redux/actions/blog";
import NotificationSection from "./NotificationSection";
import DashboardContactForm from "./DashboardContactForm";
import PostPreviewItems from "./PostPreviewItems";
import DashboardHeader from "./DashboardHeader";

const DashboardContainer = () => {
  const dispatch = useDispatch();

  const { resource, opportunity, blog } = useSelector(
    (state) => ({
      resource: state.dashboard.resource,
      opportunity: state.dashboard.opportunity,
      blog: state.dashboard.blog,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(initializeDashboard());
  }, [dispatch]);

  const handleResourceClick = (id) =>
    dispatch(navigateToPath(`/resources/${id}`));
  const handleOpportunityClick = (id) =>
    dispatch(navigateToPath(`/opportunities/${id}`));

  // for dashboard ga, second param here, post title, not necessary
  const handleBlogItemClick = (id) =>
    dispatch(loadBlogDetailPage(id, "", "dashboard"));

  const postPreviewData = { blog, resource, opportunity };
  const postPreviewHandlers = {
    handleBlogItemClick: handleBlogItemClick,
    handleResourceClick: handleResourceClick,
    handleOpportunityClick: handleOpportunityClick,
  };

  return (
    <div data-cy="div-dashboard" className="dashboard">
      <DashboardHeader />
      <div className="row tile-list">
        <NotificationSection />
        <DashboardContactForm />
        <PostPreviewItems {...postPreviewData} handlers={postPreviewHandlers} />
      </div>
    </div>
  );
};

export default DashboardContainer;
